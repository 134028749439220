import React, { useState } from 'react';
//import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import firebase from '../components/firebase'
import { navigate } from 'gatsby';

const LoginPage = (props) => {

  //const {classes} = props;

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const emailValid = userEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  const formInvalid =  userPassword === '' || !emailValid;
  const ValidationText = !emailValid ? 'Please enter valid email.' : 
        userPassword === '' ? 'Please enter password.' : 
        'Please fill out all fields in red.';       

  return (
  <Layout>
    <SEO title="Login" />
    <div className="container mx-auto h-full flex justify-center items-center">
      <form onSubmit={e => login(e, userEmail, userPassword)} 
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
            Email
            <input 
              value={userEmail} onChange={e => setUserEmail(e.target.value)}
              id="username" 
              type="text" 
              placeholder="Username" 
              className={`shadow appearance-none border ${emailValid ? `` : `border-red-500`} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} />
          </label>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
            <input 
              value={userPassword} onChange={e => setUserPassword(e.target.value)}
              id="password" 
              type="password" 
              placeholder="******************" 
              className={`shadow appearance-none border ${userPassword === '' ? `border-red-500` : ``} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} />
          </label>
      
          <label className={`${ formInvalid ? `block` : `hidden` } text-red-500 text-xs italic w-full text-center`}>
            { ValidationText }
          </label>
        </div>
        <div className="flex items-center justify-between">
          <button disabled={formInvalid} 
            type="submit"
            className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${formInvalid ? `opacity-50 cursor-not-allowed` : `hover:bg-blue-700 focus:outline-none focus:shadow-outline`}`} >
            Sign In
          </button>
          <button 
            onClick={e => resetPassword(e, userEmail)}
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
            Forgot Password?
          </button>
        </div>
  
        <div className="text-center mt-5">
          Not a member? -&nbsp; 
          <a href="/register/" 
           className="font-bold text-sm text-blue-500 hover:text-blue-800">
           Register Now!
          </a>
        </div>   
      </form>
    </div>
  </Layout>
  );

async function login(e, email, password) {

  e.preventDefault();

  try{
    if (email !== '' && password !== ''){
      await firebase.login(email, password)
      .then(function(CurUser){
        if(CurUser.emailVerified){
          navigate('/members/');
        }else{
          firebase.logout();
          navigate('/emailverification/');
        }
      });
    }else{
      return null;
    }
  }catch(error){
    alert(error.message)
  }
  
}

async function resetPassword(e, email) {

  e.preventDefault();

  try{
    if (email !== '' ){
      await firebase.passwordReset(email)
      navigate('/passwordreset/');
    }else{
      return null
    }
  }catch(error){
    alert(error.message)
  }
  
}

};

export default LoginPage;